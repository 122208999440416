<template>
    <CTabs tabs class="nav-underline nav-underline-primary">
         <CTab active>
           <template slot="title">
             <CIcon name="cil-task" />&nbsp;Filtre
           </template>
           <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                  label="SKU Kodu"
                  placeholder="Sku Kodu"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.skuCode"
                />
              </CCol>
              <CCol col="3">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6">
                <CInput
                  label="Barkod"
                  placeholder="Barkod"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.skuBarcode"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Adet"
                  placeholder="0"
                  horizontal
                  type="number"
                  v-on:keyup.enter="filter"
                  v-model="data.amount"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="SKU Ürün Adı"
                  placeholder="Ürün adı"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.productName"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                  label="Stok Alanı"
                  placeholder="Seçiniz"
                  horizontal
                  :options="stockChannels"
                  :value.sync='data.posId'
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow style="margin-top: 20px">
              <CCol sm="6">
                <CSelect
                  label="Aktif"
                  horizontal
                  :options="comboDataList"
                  :value.sync='data.activeFlag'
                >
                </CSelect>
              </CCol>
            </CRow>
           </CForm>
         </CTab>
     </CTabs>
 </template>
 
 <script>
    export default{
        name: "InventoryLogFilter",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            filter: Function
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        computed:{
          stockChannels: function() {
              let data = []
              this.$store.getters.activePos.map(r => data.push({value:r.id, label: r.name}))
              return data
           }
        },
        data: function(){
            return{
              data: this.params ? {...this.params} : {},
              comboDataList: [
                {label: "Hepsi", value: ""},
                {label: "Evet", value: "true"},
                {label: "Hayır", value: "false"}],
              }
        },
        methods:{
             clearFilters: function(){
                 this.data = {}
                 this.filter()
             }
        }
    }
 </script>